import { captureException } from "@sentry/browser";
import React from "react";
import StaticPageTemplate, { StaticPageParams } from "components/StaticPageTemplate";
import Layout from "components/common/Layout";
import { getServerSideSEO, SeoPageProps } from "helpers/seo";
import { withEnhancedServerSideProps } from "helpers/withEnhancedProps";

export type StaticPageProps = SeoPageProps;

const StaticPage = ({ seo }: StaticPageProps) => {
  return (
    <Layout initialMeta={seo}>
      <StaticPageTemplate />
    </Layout>
  );
};

export default StaticPage;

export const getServerSideProps = withEnhancedServerSideProps<StaticPageProps, StaticPageParams>(
  async ctx => {
    try {
      if (ctx.isInitialRequest) {
        const { slug: id, page } = ctx.params || {};

        if (!id) throw new Error("Missing slug");

        // Support slug + page for SEO
        const pageSlug = page ? `/${page}` : "";
        const fullId = id + pageSlug;
        const seoProps = await getServerSideSEO(ctx, fullId, "");

        return {
          props: seoProps,
        };
      }
    } catch (err) {
      captureException(err);
      return { props: { title: "" }, notFound: true };
    }

    return {
      props: { title: "" },
    };
  }
);

import React, { useEffect } from "react";
import Container from "components/common/Container";
import { FullPageLoading } from "components/common/Loading";
import { usePageMeta } from "hooks/usePageMeta";
import useRouterNavigation from "hooks/useRouterNavigation";
import { useRouterQuery } from "hooks/useRouterQuery";
import useServerSideRedirects from "hooks/useServerSideRedirects";
import Button from "../Button";
import styles from "./ErrorPage.module.css";

interface ErrorPageProps {
  errorCode?: string;
  message?: string;
  description?: string;
}

const PAGE_TITLE = "Page not found";

const ErrorPage = ({
  errorCode = "404",
  message = "Page not found",
  description = "",
}: ErrorPageProps) => {
  const router = useRouterNavigation();
  const { routerQuery } = useRouterQuery();
  usePageMeta(PAGE_TITLE);

  const { loading, hasRedirect, redirect } = useServerSideRedirects();

  useEffect(() => {
    if (hasRedirect) {
      redirect();
    }
  }, [hasRedirect, redirect]);

  if (loading || hasRedirect) return <FullPageLoading />;

  return (
    <Container>
      <div className={styles.container}>
        <h1 className={styles.pageTitle}>
          {errorCode} | {message}
        </h1>
        {description && <p className={styles.info}>{description}</p>}
        {errorCode === "404" && (
          <p className={styles.info}>
            {routerQuery.slug
              ? `You can try searching for "${routerQuery.slug}"`
              : "You can try searching using the magnifier icon at the top of this page."}
          </p>
        )}
        <Button theme="textButton" className={styles.link} onClick={() => router.back()}>
          Go Back
        </Button>
      </div>
    </Container>
  );
};

export default ErrorPage;

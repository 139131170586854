import { useEnhancedQuery } from "@uplift-ltd/apollo";
import Image from "next/image";
import React from "react";
import { PageIdType } from "__generated__/globalTypes";
import Container from "components/common/Container";
import ErrorPage from "components/common/ErrorPage";
import HtmlContent from "components/common/HtmlContent";
import { FullPageLoading } from "components/common/Loading";
import SectionHeader from "components/common/SectionHeader";
import { usePageMeta } from "hooks/usePageMeta";
import { useRouterQuery } from "hooks/useRouterQuery";
import {
  StaticPageQueryQuery as StaticPageQuery,
  StaticPageQueryVariables,
} from "./__generated__/queries";
import { STATIC_PAGE_QUERY, USER_PAGE_QUERY } from "./queries";
import { pageQuery } from "./utils";
import styles from "./StaticPageTemplate.module.css";

export { STATIC_PAGE_QUERY, USER_PAGE_QUERY };
export type { StaticPageQuery, StaticPageQueryVariables };
export type StaticPageParams = { slug: string; page?: string };

const StaticPageTemplate = () => {
  const { routerQuery } = useRouterQuery<StaticPageParams>();
  const pageSlug = routerQuery.page ? `/${routerQuery.page}` : "";
  const { data, loading, error } = useEnhancedQuery<StaticPageQuery, StaticPageQueryVariables>(
    pageQuery(routerQuery.page + pageSlug),
    {
      variables: { id: routerQuery.slug + pageSlug, idType: PageIdType.URI },
    }
  );
  const page = data?.page;

  usePageMeta(page?.title, page?.seo);

  if (loading) return <FullPageLoading />;
  if (error?.message === "403: Forbidden") {
    return (
      <ErrorPage
        errorCode="403"
        message="Forbidden"
        description="You do not have permission to access this page. Perhaps you need to be enrolled in a specific course or registered for a specific webinar."
      />
    );
  }
  if (!page) return <ErrorPage />;

  return (
    <>
      {page.heroSection?.title && page.heroSection?.summary ? (
        <SectionHeader title={page.heroSection.title} summary={page.heroSection.summary} />
      ) : null}
      {page.featuredImage?.node?.mediaItemUrl ? (
        <div className="relative h-60">
          <Image src={page.featuredImage?.node?.mediaItemUrl} layout="fill" objectFit="cover" />
        </div>
      ) : null}

      <Container>
        {page.title && <h1 className={styles.pageTitle}>{page.title}</h1>}
        {page.content && <HtmlContent>{page.content}</HtmlContent>}
      </Container>
    </>
  );
};

export default StaticPageTemplate;

import { DocumentNode } from "graphql";
import { STATIC_PAGE_QUERY, USER_PAGE_QUERY } from "./queries";

const DIRECTORIES_TO_CACHE_PER_USER = ["bundle-resources", "members"];

const isUserPage = (pageId: string | undefined): boolean =>
  DIRECTORIES_TO_CACHE_PER_USER.some(dir => pageId?.startsWith(dir));

export const pageQuery = (pageId: string | undefined): DocumentNode =>
  isUserPage(pageId) ? USER_PAGE_QUERY : STATIC_PAGE_QUERY;
